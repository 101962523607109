<template>
  <div v-scroll="updatePage" class="twitter">
    <div class="header">
      <div class="title">
        <span class="icon"></span>
        <span class="title-text">現在地周辺の投稿</span>
      </div>
      <div>
        <span class="sub-title">Twitterで投稿された災害に関わる投稿写真を閲覧できます</span>
      </div>
    </div>
    <div class="content" v-for="(post, index) in posts" :key="index">
        <div v-if="!post.noImage">
            <div class="time">{{ post.date }}</div>
            <div class="post">
                <a :href="post.link">
                    <img class="img" :src="post.media[0] ? post.media[0].media_url : null" alt="" @error="noImage(index)">
                </a>
            </div>
            <div class="tag-name">
                <div v-for="(tag, index) in post.tags" :key="index">
                    <span class="tag">{{ tag }}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
  <loader v-if="!isLoaded" />
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import useMetaTag from "@/composables/dom/useMetaTag";
import { PULL } from "@/store/modules/twitter";
export default {
    name: 'Twitter',
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const { setTitle } = useMetaTag();
        const pull = (data = {page: currentPage.value}) => {
            isLoaded.value = false;

            store.dispatch(PULL, data).then(() => {
                postData.value = JSON.parse(JSON.stringify(store.state.twitter.all));
                isLoaded.value = true;
            });
        }

        let postData = ref([]);
        let isLoaded = ref(false);
        let currentPage = ref(1);

        const updatePage = (el) => {
            const isLoading = store.getters['isShowLoader'];

            if (isLoading || parseInt(el.scrollHeight) - parseInt(el.scrollTop + el.clientHeight) > 5) {
                return;
            }

            currentPage.value++;
            pull();
        }

        const noImage = (removeIndex) => {
            postData.value[removeIndex].noImage = true;
        };

        setTitle(t("twitter.twitter_tag"));
        
        onMounted(() => {
            pull();
        });
        
        return {
            posts: computed(() => postData.value),
            pull,
            updatePage,
            noImage,
            isLoaded
        }
    }
}
</script>

<style lang="scss" scoped>
.twitter {
    max-height: 100vh;
    padding: 10px 8px;
    font-size: 16px;
    box-sizing: border-box;
    overflow-y: auto;

    & .header {
        font-family: "Hiragino Kaku Gothic ProN";
        font-weight: 600;

        & .icon {
            height: 14px;
            width: 10.5px;
            background-image: url('/img/icons/icon_pin.svg');
        }
        
        & .title {
            display: flex;
            flex-direction: row;
            align-items: center;

            &-text {
                padding-left: 5px;
            }
        }

        & .sub-title {
            font-size: 12px;
            color: #395EDF;
        }
    }

    & .content {
        margin-top: 16px;

        &:nth-of-type(2) {
            margin-top: 15px;
        }
        
        & .time {
            font-family: "Hiragino Sans";
            font-weight: 300;
            padding-bottom: 8px;
        }

        & .post {
            height: 209px;
            padding-bottom: 8px;

            a {
                height: inherit;
                display: block;
                overflow: hidden;
                border-radius: 5px;
            }

            & .img {
                width: 100%;
                height: inherit;
                object-fit: contain;
                border-radius: 5px;
            }
        }

        & .tag-name{
            display: flex;
            flex-direction: row;
            font-family: "Hiragino Sans";
            font-weight: 600;
            flex-wrap: wrap;

            & .tag {
                border: 1px solid #395EDF;
                color: #395EDF;
                font-size: 14px;
                padding: 5px 10px;
                align-items: center;
                margin-right: 8px;
                border-radius: 14px;
                line-height: 1;
                display: flex;
                justify-content: center;
                margin-bottom: 8px;
            }
        }      
    }
}

.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    img {
        height: 30px;
    }
}

</style>
